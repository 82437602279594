import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "../../../App.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import empty from "../../../Assets/empty.jpg";
import Carousel from "./carousel";
// import Addshipment from "./CreateShipment";
function Products() {
    const Navigate = useNavigate();
    // eslint-disable-next-line
    const [shipmentForm, setShipmentForm] = useState(false);
    // const [editable, setEditable] = useState(false);
    const { id } = useParams();
    let [loading, setLoading] = useState(true);
    const [deliveryboys, setDeliveryboys] = useState([]);
    const [status, setStatus] = useState();
    const [orders, setOrders] = useState([]);
    const [deliveryboy, setDeliveryBoy] = useState("");
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getorders = () => {
        Services.orderbyid("GET", null, token, id)
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.Status === 1) {
                    setOrders(res.Orders);
                    setDeliveryBoy(res?.Orders?.DeliveryBoyID);
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getcategories = () => {
        Services.getDeliveryBoy("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setDeliveryboys(res.Deliveryboys);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        getorders();
        getcategories();
        // eslint-disable-next-line
    }, []);

    const dateformatter = (cell) => {
        const date = new Date(cell);

        return (
            <p>
                <Moment format="MMMM Do YYYY hh:mm a">{date}</Moment>
            </p>
        );
    };

    const orderstatusformatter = (cell, cellcontent) => {
        const date = new Date(orders.deliveredDateTime);
        //eslint-disable-next-line
        if (cell == 1) {
            return (
                <div
                    style={{
                        display: "grid",
                        alignItems: "center",
                        justifyItems: "center",
                        justifySelf: "right",
                        gap: "0.5rem",
                    }}>
                    <div
                        className={`${
                            dashboardclasses[`order-delivered-wrapper`]
                        } ${dashboardclasses[`order-status-wrapper`]}`}>
                        <p className={dashboardclasses["order-delivered"]}>
                            Delivered
                        </p>
                    </div>
                    <Moment format="MMMM Do YYYY hh:mm a">{date}</Moment>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 2) {
            return (
                <div
                    className={`${dashboardclasses[`order-pending-wrapper`]} ${
                        dashboardclasses[`order-status-wrapper`]
                    }`}>
                    <p>Pending</p>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 3) {
            return (
                <div
                    className={`${
                        dashboardclasses[`order-delivered-wrapper`]
                    } ${dashboardclasses[`order-status-wrapper`]}`}>
                    <p>Shipment Order Created</p>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 7 || cell == 5) {
            return (
                <div
                    className={`${dashboardclasses[`order-returned-wrapper`]} ${
                        dashboardclasses[`order-status-wrapper`]
                    }`}>
                    <p>Cancelled</p>
                </div>
            );
        }
        //eslint-disable-next-line
        if (cell == 6) {
            return (
                <div className={dashboardclasses[`cancel-wrapper`]}>
                    <p className={dashboardclasses["cancel"]}>Returned </p>
                </div>
            );
        }
    };
    const statuschangehandler = (e) => {
        setStatus(e.target.value);
    };
    const statusHandler = (e) => {
        setLoading(true);
        let body = {
            OrderStatus: status,
            UserID: orders?.UserID,
        };
        Services.UpdateOrderDetails("PUT", JSON.stringify(body), token, id)
            .then((res) => {
                if (res.Status === 1) {
                    getorders();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const updateDeliveryBoy = (e) => {
        setLoading(true);
        let body = {
            UserID: orders?.UserID,
            DeliveryBoyID: deliveryboy,
        };
        Services.updateDeliveryboyOrder("PUT", JSON.stringify(body), token, id)
            .then((res) => {
                if (res.Status === 1) {
                    getorders();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    // const addshipmentHandler = () => {
    //     setShipmentForm((prev) => !prev);
    // };

    return (
        <>
            {/* {shipmentForm && (
                <Addshipment
                    setmodal={setShipmentForm}
                    editable={editable}
                    setEditable={setEditable}
                    id={id}
                    getorders={getorders}
                />
            )} */}
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="orders" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses.CategoryWrapper}>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <button
                                    className={dashboardclasses["back"]}
                                    onClick={() => Navigate(-1)}>
                                    Back
                                </button>
                                <header
                                    className={
                                        dashboardclasses["order-details-header"]
                                    }>
                                    <h3>Order Details</h3>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "order-header-item"
                                            ]
                                        }>
                                        <h5>ORDER ID</h5>

                                        <p>#{orders.OrderID}</p>
                                    </div>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "order-header-item"
                                            ]
                                        }>
                                        <h5>ORDER DATE TIME</h5>

                                        {dateformatter(orders.CreatedAt)}
                                    </div>
                                </header>
                                <div
                                    className={
                                        dashboardclasses[
                                            "orders-form-invoice-wrapper"
                                        ]
                                    }>
                                    <div
                                        className={
                                            dashboardclasses[
                                                "orders-form-invoice"
                                            ]
                                        }>
                                        <div
                                            className={
                                                dashboardclasses[
                                                    "invoice-detail"
                                                ]
                                            }>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "detail-status"
                                                    ]
                                                }
                                                style={{ flexWrap: "wrap" }}>
                                                <span>status:</span>
                                                <select
                                                    defaultValue={
                                                        orders.OrderStatus
                                                    }
                                                    onChange={
                                                        statuschangehandler
                                                    }
                                                    disabled={
                                                        orders.OrderStatus ==
                                                            1 ||
                                                        orders.OrderStatus == 4
                                                    }>
                                                    {" "}
                                                    <option value={1}>
                                                        DELIVERED{" "}
                                                    </option>
                                                    <option value={2}>
                                                        PACKING
                                                    </option>
                                                    <option value={4}>
                                                        CANCELLED{" "}
                                                    </option>
                                                    <option value={5}>
                                                        PICKED UP
                                                    </option>
                                                </select>
                                                <button onClick={statusHandler}>
                                                    Update
                                                </button>
                                            </div>
                                            {orderstatusformatter(
                                                orders.orderStatus
                                            )}
                                            <div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "detail-status"
                                                        ]
                                                    }
                                                    style={{
                                                        flexWrap: "wrap",
                                                    }}>
                                                    {/* <span>Deliveryboy:</span> */}
                                                    <select
                                                        value={deliveryboy}
                                                        onChange={(e) =>
                                                            setDeliveryBoy(
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={
                                                            orders.OrderStatus ==
                                                                1 ||
                                                            orders.OrderStatus ==
                                                                4
                                                        }>
                                                        {" "}
                                                        <option value="0">
                                                            Select Delivery Boy
                                                        </option>
                                                        {deliveryboys.map(
                                                            (item) => (
                                                                <option
                                                                    value={
                                                                        item?.AdminID
                                                                    }>
                                                                    {
                                                                        item?.FirstName
                                                                    }{" "}
                                                                    {item?.LastName !=
                                                                        "NA" &&
                                                                        item?.LastName}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <button
                                                        onClick={
                                                            updateDeliveryBoy
                                                        }>
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={
                                                dashboardclasses["order-items"]
                                            }>
                                            <header
                                                className={
                                                    dashboardclasses[
                                                        "customer-info-header"
                                                    ]
                                                }>
                                                <h4>Customer Info</h4>
                                                {/* eslint-disable-next-line */}
                                                {/* {orders.isShipmentCreated ==
                                                    0 && (
                                                    <button
                                                        className={
                                                            dashboardclasses[
                                                                "add-shipment"
                                                            ]
                                                        }
                                                        onClick={
                                                            addshipmentHandler
                                                        }>
                                                        +Add Shipment Order
                                                    </button>
                                                )} */}
                                            </header>

                                            <div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-item-data"
                                                        ]
                                                    }>
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>Name</h5>
                                                        <h4>:</h4>
                                                        <p>{orders.UserName}</p>
                                                    </div>

                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>Contact Number</h5>
                                                        <h4>:</h4>
                                                        <p>
                                                            {
                                                                orders.MobileNumber
                                                            }
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>Email Id</h5>
                                                        <h4>:</h4>
                                                        <p> {orders.EmailID}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-products"
                                                    ]
                                                }>
                                                <h4>Ordered items </h4>
                                                {orders?.OrderItems?.map(
                                                    (item) => {
                                                        return (
                                                            <div
                                                                className={
                                                                    dashboardclasses[
                                                                        "items-data"
                                                                    ]
                                                                }>
                                                                {/*  <img
                                                                    src={
                                                                        item.ImageUrl ===
                                                                        "NA"
                                                                            ? empty
                                                                            : item.ImageUrl
                                                                    }
                                                                    alt="product"
                                                                /> */}
                                                                <Carousel
                                                                    images={
                                                                        item?.ProductImages
                                                                    }
                                                                />

                                                                <div
                                                                    className={
                                                                        dashboardclasses[
                                                                            "items-data-desc"
                                                                        ]
                                                                    }>
                                                                    <h2>
                                                                        {
                                                                            item.ProductName
                                                                        }
                                                                    </h2>
                                                                    <p
                                                                        className={
                                                                            dashboardclasses[
                                                                                "Ordered-items-price"
                                                                            ]
                                                                        }>
                                                                        ₹
                                                                        {
                                                                            item.OfferPrice
                                                                        }
                                                                    </p>
                                                                    <p>
                                                                        {
                                                                            item.metricvalue
                                                                        }{" "}
                                                                        {
                                                                            item.metrictype
                                                                        }{" "}
                                                                        x{"   "}
                                                                        {
                                                                            item.Quantity
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-item-data"
                                                    ]
                                                }>
                                                <h4>Shipping Details </h4>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>
                                                        Delivery Boy Travelled
                                                        Distance
                                                    </h5>
                                                    <h4>:</h4>
                                                    <p>{orders.Distance} Km</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Address</h5>
                                                    <h4>:</h4>
                                                    <p>{orders.Address}</p>
                                                </div>

                                                {/* <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Delivery Boy Name</h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        {orders.DeliveryBoyName}
                                                    </p>
                                                </div> */}
                                                {orders.OrderStatus == 1 && (
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>
                                                            Delivery Date Time
                                                        </h5>
                                                        <h4>:</h4>
                                                        <p>
                                                            {dateformatter(
                                                                orders.deliveredDateTime
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Landmark</h5>
                                                    <h4>:</h4>
                                                    <p>{orders.LandMark}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Contact Number</h5>
                                                    <h4>:</h4>
                                                    <p>{orders.MobileNumber}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Email Id</h5>
                                                    <h4>:</h4>
                                                    <p> {orders.EmailID}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>invoice</h5>
                                                    <h4>:</h4>
                                                    <a
                                                        href={
                                                            orders.InvoiceFile
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        Click me
                                                    </a>
                                                </div>
                                                {/* <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>AWB Number</h5>
                                                    <h4>:</h4>
                                                    <p> {orders.AWBNumber}</p>
                                                </div> */}

                                                {/* {orders.isShipmentCreated ===
                                                    1 && (
                                                    <>
                                                        <div
                                                            className={
                                                                dashboardclasses[
                                                                    "order-date"
                                                                ]
                                                            }>
                                                            <h5>
                                                                Shiprocket Order
                                                                Id
                                                            </h5>
                                                            <h4>:</h4>
                                                            <p>
                                                                {" "}
                                                                {
                                                                    orders.ShiprocketOrderID
                                                                }
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                dashboardclasses[
                                                                    "order-date"
                                                                ]
                                                            }>
                                                            <h5>
                                                                Tracking URL
                                                            </h5>
                                                            <h4>:</h4>
                                                            {orders.trackingUrl !==
                                                            "NA" ? (
                                                                <a
                                                                    href={
                                                                        orders.trackingUrl
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer">
                                                                    {
                                                                        orders.trackingUrl
                                                                    }
                                                                </a>
                                                            ) : (
                                                                <p>-</p>
                                                            )}
                                                        </div>
                                                    </>
                                                )} */}
                                            </div>
                                            <div
                                                className={
                                                    dashboardclasses[
                                                        "order-item-data"
                                                    ]
                                                }>
                                                <h4> Payment Details </h4>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Payment Mode</h5>
                                                    <h4>:</h4>
                                                    {orders.PaymentMethod ===
                                                    "COD" ? (
                                                        <p> COD</p>
                                                    ) : (
                                                        <p>ONLINE</p>
                                                    )}
                                                </div>
                                                {orders.PaymentMethod ===
                                                    "Online" && (
                                                    <div
                                                        className={
                                                            dashboardclasses[
                                                                "order-date"
                                                            ]
                                                        }>
                                                        <h5>PaymentID</h5>
                                                        <h4>:</h4>
                                                        <p>
                                                            {
                                                                orders.PaymentTransactionID
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>SubTotal</h5>
                                                    <h4>:</h4>
                                                    <p> ₹ {orders.SubTotal}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Tax Amount</h5>
                                                    <h4>:</h4>
                                                    <p>₹{orders.TaxAmount}</p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Discount Amount</h5>
                                                    <h4>:</h4>
                                                    <p>₹ {orders.Discount}</p>
                                                </div>

                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Coupon Code</h5>
                                                    <h4>:</h4>
                                                    <p> {orders.PromoCode}</p>
                                                </div>

                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Delivery Charges</h5>
                                                    <h4>:</h4>
                                                    <p>
                                                        {" "}
                                                        ₹{" "}
                                                        {orders.DeliverCharges}
                                                    </p>
                                                </div>
                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Tip</h5>
                                                    <h4>:</h4>
                                                    <p> ₹ {orders.TipAmount}</p>
                                                </div>

                                                <div
                                                    className={
                                                        dashboardclasses[
                                                            "order-date"
                                                        ]
                                                    }>
                                                    <h5>Grand Total</h5>
                                                    <h4>:</h4>

                                                    <p>₹ {orders.GrandTotal}</p>
                                                </div>
                                            </div>
                                            {/* <div className="category-table">
                                            <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={orders.orderitems}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            pagination={pagination}
                                                wrapperClasses="table-responsive"
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Products;
