// Authorization: `token ${token}`,
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,
        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    }).then((response) => response.json());
};

const signup = (method, body) => {
    return httpcall("adminusers/signup", method, body);
};

const login = (method, body) => {
    return httpcall("adminuser/login", method, body);
};

const getRoutes = (method, body, token, status) => {
    return httpcall(
        `routes/admin/bystatus?Status=${status}`,
        method,
        body,
        token
    );
};
const AddRoutes = (method, body, token) => {
    return httpcall(`routes/admin/add`, method, body, token);
};
const getRoutebyId = (method, body, token, id) => {
    return httpcall(`routes/admin/detail/${id}`, method, body, token);
};
const RouteUpdate = (method, body, token, id) => {
    return httpcall(`routes/admin/update/${id}`, method, body, token);
};
const DeleteRoutes = (method, body, token, id) => {
    return httpcall(`routes/admin/delete/${id}`, method, body, token);
};
const getCategories = (method, body, token) => {
    return httpcall("categories/allcategoriesadmin", method, body, token);
};
const AddCategories = (method, body, token) => {
    return httpcall("categories/addcategory", method, body, token);
};
const updateDeliveryboyOrder = (method, body, token, OrderID) => {
    return httpcall(`adminorders/assigndeliverboy/${OrderID}`, method, body, token);
};
const getCategoriesbyId = (method, body, token, id) => {
    return httpcall(`categories/detail/${id}`, method, body, token);
};
const updatecategories = (method, body, token, id) => {
    return httpcall(`categories/updatecategory/${id}`, method, body, token);
};
const Deletecategories = (method, body, token, id) => {
    return httpcall(`categories/deletecategory/${id}`, method, body, token);
};

const getBanners = (method, body, token) => {
    return httpcall("banners", method, body, token);
};
const AddBanners = (method, body, token) => {
    return httpcall("banners/addbanner", method, body, token);
};
const getBannerbyId = (method, body, token, id) => {
    return httpcall(`banners/detail/${id}`, method, body, token);
};
const updateBanner = (method, body, token, id) => {
    return httpcall(`banners/updatebanner/${id}`, method, body, token);
};
const DeleteBannner = (method, body, token, id) => {
    return httpcall(`banners/deletebanner/${id}`, method, body, token);
};

const GetSubCategory = (method, body, token, id) => {
    return httpcall(`subcategories/bycategory/${id}`, method, body, token);
};
const GetSubCategoryAll = (method, body, token, id) => {
    return httpcall(`subcategories`, method, body, token);
};
const AddSubCategory = (method, body, token) => {
    return httpcall(`subcategories/add/`, method, body, token);
};
const getSubCategoryById = (method, body, token, id) => {
    return httpcall(`subcategories/detail/${id}`, method, body, token);
};
const updateSubcategory = (method, body, token, id) => {
    return httpcall(`subcategories/update/${id}`, method, body, token);
};
const DeleteSubcategory = (method, body, token, id) => {
    return httpcall(`subcategories/delete/${id}`, method, body, token);
};

const getProductsbyCatId = (method, body, token, id) => {
    return httpcall(
        `adminproducts/bycategory?CategoryID=${id}`,
        method,
        body,
        token
    );
};
const AddProduct = (method, body, token) => {
    return httpcall(`adminproducts/addproduct`, method, body, token);
};

const getProductsByProductId = (method, body, token, id) => {
    return httpcall(`adminproducts/productdetail/${id}`, method, body, token);
};
const updateProduct = (method, body, token, id) => {
    return httpcall(`adminproducts/updateproduct/${id}`, method, body, token);
};
const updateProductVarient = (method, body, token, id) => {
    return httpcall(
        `adminproducts/updateproductvariant/${id}`,
        method,
        body,
        token
    );
};

const deleteProductWithVarient = (method, body, token, id) => {
    return httpcall(`adminproducts/deleteproduct/${id}`, method, body, token);
};
const addVarient = (method, body, token) => {
    return httpcall(
        `adminproducts/addvariant
    `,
        method,
        body,
        token
    );
};
const deleteVarient = (method, body, token, id) => {
    return httpcall(`adminproducts/deletevariant/${id}`, method, body, token);
};
const getDeliveryBoy = (method, body, token) => {
    return httpcall("adminuser/getalldeliveryboys", method, body, token);
};
const AddDeliveryBoy = (method, body, token) => {
    return httpcall(
        `adminuser/addeliveryboy
    `,
        method,
        body,
        token
    );
};
const getDeliveryBoyByID = (method, body, token, id) => {
    return httpcall(
        `adminuser/deliveryboydetail?AdminID=${id}`,
        method,
        body,
        token
    );
};
const updateDeliveryboy = (method, body, token, id) => {
    return httpcall(`adminuser/updatedeliveryboy/${id}`, method, body, token);
};

const deleteDeliveryBoy = (method, body, token, id) => {
    return httpcall(`adminuser/deletedeliveryboy/${id}`, method, body, token);
};
const getwarehouses = (method, body, token) => {
    return httpcall("warehouses/all", method, body, token);
};

const getWarehousebyId = (method, body, token, id) => {
    return httpcall(`warehouses/detail/${id}`, method, body, token);
};
const AddWarehouse = (method, body, token) => {
    return httpcall("warehouses/add", method, body, token);
};
const updateWarehouse = (method, body, token, id) => {
    return httpcall(`warehouses/update/${id}`, method, body, token);
};
const DeleteWarehouse = (method, body, token, id) => {
    return httpcall(`warehouses/delete/${id}`, method, body, token);
};

const getUsers = (method, body, token) => {
    return httpcall("adminuser/userslist", method, body, token);
};
const Getuserbyid = (method, body, token, id) => {
    return httpcall(`adminuser/userdetail/${id}`, method, body, token);
};
const updateuser = (method, body, token, id) => {
    return httpcall(`adminuser/user/update/${id}`, method, body, token);
};
const DeleteUser = (method, body, token, id) => {
    return httpcall(`adminuser/user/delete/${id}`, method, body, token);
};

const getPromoCode = (method, body, token) => {
    return httpcall("promocodes/admin/all", method, body, token);
};
const addPromoCode = (method, body, token) => {
    return httpcall("promocodes/admin/add", method, body, token);
};

const getpromocodeByID = (method, body, token, id) => {
    return httpcall(`promocodes/admin/detail/${id}`, method, body, token);
};
const updatepromocode = (method, body, token, id) => {
    return httpcall(`promocodes/admin/update/${id}`, method, body, token);
};
const DeletePromocode = (method, body, token, id) => {
    return httpcall(`promocodes/admin/delete/${id}`, method, body, token);
};
const getAllCurrentOrders = (method, body, token) => {
    return httpcall(`adminorders/currentorders`, method, body, token);
};
const getAllPastOrders = (method, body, token) => {
    return httpcall(`adminorders/pastorders`, method, body, token);
};
const orderbyid = (method, body, token, id) => {
    return httpcall(
        `adminorders/orderdetail?OrderID=${id}`,
        method,
        body,
        token
    );
};
const getAdminusers = (method, body, token) => {
    return httpcall("adminusers/list", method, body, token);
};
const addAdminUser = (method, body, token) => {
    return httpcall("adminusers/addadmin", method, body, token);
};
const AdminUserById = (method, body, token, id) => {
    return httpcall(`adminusers/detail/${id}`, method, body, token);
};
const getOrderByStatus = (method, body, token, id) => {
    return httpcall(`adminorders?orderStatus=${id}`, method, body, token);
};
const updateAdmindata = (method, body, token, id) => {
    return httpcall(`adminusers/updateadminuser/${id}`, method, body, token);
};
const deleteAdmindata = (method, body, token, id) => {
    return httpcall(`adminusers/deleteadminuser/${id}`, method, body, token);
};
const updateprofile = (method, body, token, id) => {
    return httpcall(`adminusers/update/${id}`, method, body, token);
};
const getFranchiseForm = (method, body, token) => {
    return httpcall(`forms/franchiseEnquiryforms`, method, body, token);
};
const getCallbackForm = (method, body, token) => {
    return httpcall(`forms/callbackforms`, method, body, token);
};
const getAvailabilityform = (method, body, token) => {
    return httpcall(`forms/availabilityforms`, method, body, token);
};
const orderByUserId = (method, body, token, id) => {
    return httpcall(`adminorders?userId=${id}`, method, body, token);
};
const UpdateOrderDetails = (method, body, token, id) => {
    return httpcall(`adminorders/updatestatus/${id}`, method, body, token);
};
const getNotifyProducts = (method, body, token) => {
    return httpcall(`adminusers/notifyproducts`, method, body, token);
};
const updateNotifyProducts = (method, body, token, id) => {
    return httpcall(
        `adminusers/notifyproductupdate/${id}`,
        method,
        body,
        token
    );
};
const AddShipmentData = (method, body, token) => {
    return httpcall(`shiprocket/createorder`, method, body, token);
};
const getCallBackById = (method, body, token, id) => {
    return httpcall(`forms/callback/${id}`, method, body, token);
};
const getFranchiseById = (method, body, token, id) => {
    return httpcall(`forms/franchiseEnquiry/${id}`, method, body, token);
};
const getAvailabilityById = (method, body, token, id) => {
    return httpcall(`forms/availability/${id}`, method, body, token);
};
const getNotifyById = (method, body, token, id) => {
    return httpcall(
        `adminusers/notifyproductdetail/${id}`,
        method,
        body,
        token
    );
};

const updateBulkPrices = (method, body, token, filename) => {
    return httpcall(`productcsv/update/${filename}`, method, body, token);
};
const sendNotification = (method, body, token) => {
    return httpcall(`adminuser/sendnotifications`, method, body, token);
};
const getsettings = (method, body, token) => {
    return httpcall(`adminuser/Settingslist`, method, body, token);
};
const getsettingsbyid = (method, body, token, id) => {
    return httpcall(`adminuser/settingdetail/${id}`, method, body, token);
};
const addsettings = (method, body, token) => {
    return httpcall(`adminuser/addsettings`, method, body, token);
};
const updatesettings = (method, body, token, id) => {
    return httpcall(`adminuser/updatesettings/${id}`, method, body, token);
};
const deletesettings = (method, body, token, id) => {
    return httpcall(`adminuser/deletesettings/${id}`, method, body, token);
};
const getKits = (method, body, token) => {
    return httpcall(`adminproducts/allkits`, method, body, token);
};
const addKit = (method, body, token) => {
    return httpcall(`adminproducts/addkit`, method, body, token);
};
const KitsDetails = (method, body, token, id) => {
    return httpcall(`adminproducts/kitdetail/${id}`, method, body, token);
};
const updateKit = (method, body, token, id) => {
    return httpcall(`adminproducts/updatekit/${id}`, method, body, token);
};
const updateKititem = (method, body, token, id) => {
    return httpcall(`adminproducts/updatekititem/${id}`, method, body, token);
};
const DeleteKit = (method, body, token, id) => {
    return httpcall(`adminproducts/deletekit/${id}`, method, body, token);
};
const DeleteKititem = (method, body, token, id) => {
    return httpcall(`adminproducts/deletekititem/${id}`, method, body, token);
};
const SearchProduct = (method, body, token, search) => {
    return httpcall(`adminproducts/search/${search}`, method, body, token);
};
const AddKitvarient = (method, body, token) => {
    return httpcall(`adminproducts/addkititem`, method, body, token);
};
const downloadcsv = (method, body, token, filename) => {
    return httpcall(`csv/${filename}`, method, body, token);
};
const getRecommendedProducts = (method, body, token) => {
    return httpcall(`adminproducts/recommendedproducts`, method, body, token);
};
const AddRecommendedProducts = (method, body, token) => {
    return httpcall(`adminproducts/addrecommendedproduct`, method, body, token);
};
const deleteRecommendedProducts = (method, body, token, id) => {
    return httpcall(
        `adminproducts/deleterecommendedproducts/${id}`,
        method,
        body,
        token
    );
};
const GetTimings = (method, body, token) => {
    return httpcall(
        `admintiming`,
        method,
        body,
        token
    );
};
const AddTimings = (method, body, token) => {
    return httpcall(
        `admintiming/add`,
        method,
        body,
        token
    );
};
const UpdateTimings = (method, body, token, id) => {
    return httpcall(
        `admintiming/update/${id}`,
        method,
        body,
        token
    );
};
const DeleteTimings = (method, body, token, id) => {
    return httpcall(
        `admintiming/delete/${id}`,
        method,
        body,
        token
    );
};
const UpdateToggleStore = (method, body, token, id) => {
    return httpcall(
        `admintiming/toggle?status=${id}`,
        method,
        body,
        token
    );
};
const getShopStatus = (method, body, token) => {
    return httpcall(
        `admintiming/shopstatus`,
        method,
        body,
        token
    );
};
const updateStoreData = (method, body, token, id) => {
    return httpcall(
        `admintiming/updatesettings/${id}`,
        method,
        body,
        token
    );
};


export const Services = {
    signup,
    getKits,
    updateStoreData,
    getShopStatus,
    DeleteTimings,
    AddTimings,
    login,
    getCategories,
    UpdateToggleStore,
    GetTimings,
    AddCategories,
    getCategoriesbyId,
    updatecategories,
    UpdateTimings,
    Deletecategories,
    getwarehouses,
    getWarehousebyId,
    AddWarehouse,
    updateWarehouse,
    DeleteWarehouse,
    getBanners,
    AddBanners,
    getBannerbyId,
    updateBanner,
    DeleteBannner,
    getUsers,
    updateuser,
    DeleteUser,
    Getuserbyid,
    getProductsbyCatId,
    updateDeliveryboyOrder,
    // getProductsByCategoryId,
    getProductsByProductId,
    updateProduct,
    getPromoCode,
    addPromoCode,
    getpromocodeByID,
    updatepromocode,
    DeletePromocode,
    getAllCurrentOrders,
    orderbyid,
    getAdminusers,
    addAdminUser,
    AdminUserById,
    getOrderByStatus,
    updateAdmindata,
    deleteAdmindata,
    updateprofile,
    getFranchiseForm,
    getCallbackForm,
    getAvailabilityform,
    orderByUserId,
    UpdateOrderDetails,
    getNotifyProducts,
    updateNotifyProducts,
    AddShipmentData,
    getCallBackById,
    getFranchiseById,
    getAvailabilityById,
    getNotifyById,
    GetSubCategory,
    AddSubCategory,
    getSubCategoryById,
    DeleteSubcategory,
    updateSubcategory,
    AddProduct,
    deleteProductWithVarient,
    updateProductVarient,
    addVarient,
    deleteVarient,
    getDeliveryBoy,
    AddDeliveryBoy,
    getDeliveryBoyByID,
    updateDeliveryboy,
    deleteDeliveryBoy,
    getAllPastOrders,
    GetSubCategoryAll,
    getRoutes,
    DeleteRoutes,
    getRoutebyId,
    AddRoutes,
    RouteUpdate,
    updateBulkPrices,
    sendNotification,
    getsettings,
    getsettingsbyid,
    updatesettings,
    addsettings,
    deletesettings,
    KitsDetails,
    addKit,
    updateKit,
    DeleteKit,
    SearchProduct,
    AddKitvarient,
    updateKititem,
    DeleteKititem,
    downloadcsv,
    getRecommendedProducts,
    AddRecommendedProducts,
    deleteRecommendedProducts,
};
