import React, { useState, useEffect } from "react";
import dashboardclasses from "../dashboard.module.css";
import { IoClose } from "react-icons/io5";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useForm } from "react-hook-form";
// import { TagsInput } from "react-tag-input-component";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import { config } from "../../../s3Config";
import S3FileUpload from "react-s3";
import Spinner from "../updateBulk/LoaderModal";

import { useNavigate } from "react-router-dom";
window.Buffer = window.Buffer || require("buffer").Buffer;

function AddCategory(props) {
    const [categoryItems, setCategoryItems] = useState([]);
    const [subCategoryItems, setSubCategoryItems] = useState([]);
    const [Paths, setPaths] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const Navigate = useNavigate();
    let [uploadSpinner, setUploadSpinner] = useState(false);

    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    const [file, setFile] = useState("");
    const [base64, setbase64] = useState("");
    const formData = watch();

    const getsubcategory = (id, response) => {
        Services.GetSubCategoryAll("GET", null, token, id)
            .then((res) => {
                if (res.Status === 1) {
                    setSubCategoryItems(res.SubCategories);
                    if (props.editable) {
                        setTimeout(() => {
                            reset({
                                ProductName: response.Products.ProductName,
                                CategoryID: response.Products.CategoryID,
                                Type: response.Products.Type,
                                Status: response.Products.Status,
                                Description: response.Products.Description,
                                SubCategoryID: response.Products.SubCategoryID,
                            });
                        }, 500);
                    }
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        Services.getCategories("GET", null, token)
            .then((res) => {
                if (res.Status === 1) {
                    setCategoryItems(res.Categories);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });

        if (props.editable) {
            Services.getProductsByProductId("GET", null, token, props.id)
                .then((res) => {
                    console.log(res);
                    if (res?.Status === 1) {
                        getsubcategory(res.Products.CategoryID, res);
                        setPaths(
                            res?.Products?.ProductImages?.map((item) => ({
                                location: item,
                                path: String(item).split("/").slice(-1)[0],
                            }))
                        );
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    const CancelHandler = (e) => {
        e.stopPropagation();
        props.setmodal(false);
        props.setEditable(false);
    };

    const openhandler = (e) => {
        e.stopPropagation();
    };
    const categoryChange = (e) => {
        getsubcategory(e.target.value);
    };
    const subcategoryClick = () => {
        if (formData.CategoryID === "") {
            alert("please select category First");
        }
    };
    // const fileTypes = ["JPEG", "PNG"];
    const handleChange = (files) => {
        console.log(files);
        setUploadSpinner(true);
        const fileArray = Array.from(files); // Convert to array if necessary

        for (let i = 0; i < files.length; i++) {
            const timestamp = Date.now();

            if (
                files[i].type === "image/png" ||
                files[i].type === "image/jpeg" ||
                files[i].type === "image/jpg" ||
                files[i].type === "image/webp"
            ) {
                let maxSize = 2 * 1024 * 1024;
                if (file.size > maxSize) {
                    alert("File size cannot be greater than 2 MB");
                    return;
                }

                const newfile = new File(
                    [files[i]],
                    `${timestamp}.${String(files[i].type).split("/")[1]}`
                );
                S3FileUpload.uploadFile(newfile, {
                    ...config,
                    dirName: "Products",
                })
                    .then((res) => {
                        console.log(res);
                        setPaths((prev) => [
                            ...prev,
                            {
                                location: res.location,
                                path: `${timestamp}.${
                                    String(files[i].type).split("/")[1]
                                }`,
                            },
                        ]);
                        if (i == files.length - 1) {
                            setUploadSpinner(false);
                        }
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert("please upload file in Image format ");
                setUploadSpinner(false);
                break;
            }
        }
        setUploadSpinner(false);
        return;
    };

    const fileuploadDesign = (
        <div className={dashboardclasses["add-item"]}>
            <BsCloudUpload className={dashboardclasses["upload-icon"]} />
            <h5>Drag Your Image here</h5>
            <p>(Only *.jpeg and *.png images will be accepted)</p>
        </div>
    );
    const onSubmit = (data) => {
        let image;
        if (base64.length > 0) {
            image = { ImageUrl: base64 };
        }
        console.log(data);
        let method = JSON.stringify({
            CategoryID: formData.CategoryID,
            SubCategoryID: formData.SubCategoryID,
            ProductName: formData.ProductName,
            Description: formData.Description,
            Type: 1,
            ProductVariants: [
                {
                    metrictype: formData.metrictype,
                    AvailableQty: formData.AvailableQty,
                    metricvalue: formData.metricvalue,
                    Price: formData.Price,
                    OfferPrice: formData.OfferPrice,
                },
            ],
            ProductImages: Paths.map((item) => item.path),
        });
        if (!props.editable && Paths.length > 0) {
            Services.AddProduct("POST", method, token)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories(props.categoryId);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (props.editable) {
            method = JSON.stringify({
                CategoryID: formData.CategoryID,
                SubCategoryID: formData.SubCategoryID,
                ProductName: formData.ProductName,
                Description: formData.Description,
                Status: formData.Status,
                ProductImages: Paths.map((item) => item.path),

                Type: 1,
            });
            Services.updateProduct("PUT", method, token, props.id)
                .then((res) => {
                    if (res.Status === 1) {
                        props.setmodal(false);
                        props.getCategories(props.categoryId);
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else if (fileNames.Paths === 0) {
            alert("please upload image");
        }
    };
    const deleteHandler = (link) => {
        setPaths((prev) => prev.filter((item) => item?.location != link));
    };
    return (
        <div className={dashboardclasses.modal} onClick={CancelHandler}>
            {uploadSpinner && <Spinner />}

            <div
                className={dashboardclasses["modal-content"]}
                onClick={openhandler}>
                <div className={dashboardclasses["modal-content-header"]}>
                    <div>
                        <h3>
                            {props.editable ? "Update Product" : "Add Product"}
                        </h3>
                        <p>
                            {props.editable ? "Update Product" : "Add Product"}{" "}
                            and necessary information from here
                        </p>
                    </div>
                    <button
                        className={dashboardclasses["close-btn"]}
                        onClick={CancelHandler}>
                        <IoClose size={22} color={"red"} />
                    </button>
                </div>
                <form
                    className={dashboardclasses["add-items"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="ProductName">Product Name</label>
                        <div className={dashboardclasses["select"]}>
                            <input
                                {...register("ProductName", {
                                    required: "Product Name is required",
                                })}
                            />

                            {errors?.ProductName && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.ProductName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="Category">Category</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("CategoryID", {
                                    required: "Category is required",
                                    onChange: (e) => {
                                        categoryChange(e);
                                    },
                                })}>
                                <option value="" disabled>
                                    Select Category
                                </option>
                                {categoryItems?.map((item) => {
                                    return (
                                        <option value={item?.CategoryID}>
                                            {item?.CategoryName}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors?.CategoryID && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.CategoryID?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="subCategory">SubCategory</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                onClick={subcategoryClick}
                                defaultValue=""
                                {...register("SubCategoryID", {
                                    required: "subCategory is required",
                                })}>
                                <option value="" disabled>
                                    Select Subcategory
                                </option>
                                {subCategoryItems?.map((item) => {
                                    return (
                                        <option value={item?.SubCategoryID}>
                                            {item?.SubCategoryName}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors?.SubCategoryID && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.SubCategoryID?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    {/* <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="Type">Type</label>
                        <div className={dashboardclasses["select"]}>
                            <select
                                defaultValue=""
                                {...register("Type", {
                                    required: "Type is required",
                                })}>
                                <option value="" disabled>
                                    Select Type
                                </option>
                                <option value={1}>Product</option>
                                <option value={2}>Kits</option>
                            </select>
                            {errors?.Type && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Type?.message}
                                </p>
                            )}
                        </div>
                    </div> */}
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="image">Image</label>

                        <FileUploader
                            classes={dashboardclasses["upload-image"]}
                            multiple={true}
                            handleChange={handleChange}
                            name="file"
                            children={fileuploadDesign}
                            // types={fileTypes}
                        />
                    </div>
                    <div className={dashboardclasses["upload_file_product"]}>
                        <label htmlFor=""></label>
                        {Paths.length > 0 &&
                            Paths.map((item) => (
                                <div>
                                    <img src={item?.location} alt="" />
                                    <button
                                        onClick={() =>
                                            deleteHandler(item.location)
                                        }>
                                        X
                                    </button>
                                </div>
                            ))}
                    </div>

                    {/* {!props.editable && (
                        <>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metrictype">Metric Type</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        {...register("metrictype", {
                                            required: "Metric Type is required",
                                        })}
                                    />

                                    {errors?.metrictype && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metrictype?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="AvailableQty">
                                    Available Qty
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        {...register("AvailableQty", {
                                            required:
                                                "Available Qty is required",
                                        })}
                                    />

                                    {errors?.AvailableQty && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.AvailableQty?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metricvalue">
                                    Metric value
                                </label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        {...register("metricvalue", {
                                            required:
                                                "Metric value is required",
                                        })}
                                    />

                                    {errors?.metricvalue && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.metricvalue?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="metricvalue">Price</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        {...register("Price", {
                                            required: "Price is required",
                                        })}
                                    />

                                    {errors?.Price && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.Price?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className={
                                    dashboardclasses["item-image-upload"]
                                }>
                                <label htmlFor="OfferPrice">Offer Price</label>
                                <div className={dashboardclasses["select"]}>
                                    <input
                                        type={"number"}
                                        {...register("OfferPrice", {
                                            required: "Offer Price is required",
                                        })}
                                    />

                                    {errors?.OfferPrice && (
                                        <p
                                            className={
                                                dashboardclasses["error"]
                                            }>
                                            {errors?.OfferPrice?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </>
                    )} */}

                    {props.editable && (
                        <div className={dashboardclasses["item-image-upload"]}>
                            <label htmlFor="status">Status</label>
                            <div className={dashboardclasses["select"]}>
                                <select
                                    {...register("Status", {
                                        required: "status Is required",
                                    })}>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                                {errors?.Status && (
                                    <p className={dashboardclasses["error"]}>
                                        {errors?.Status?.message}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={dashboardclasses["item-image-upload"]}>
                        <label htmlFor="Description">Description</label>
                        <div className={dashboardclasses["select"]}>
                            <textarea
                                rows={5}
                                maxLength={473}
                                {...register("Description", {
                                    maxLength: {
                                        value: 473,
                                        message:
                                            "Maximum length is 473 characters",
                                    },
                                })}></textarea>

                            {errors?.Description && (
                                <p className={dashboardclasses["error"]}>
                                    {errors?.Description?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={dashboardclasses["add-btn"]}>
                        <button type="button" onClick={CancelHandler}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={dashboardclasses["add-category-btn"]}>
                            {props.editable ? "update" : "Add Product"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategory;
