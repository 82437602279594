import React, { useState, useEffect } from "react";
import TopNav from "../../Navbar/topNav";
import SideNav from "../../Navbar/sideNav";
import dashboardclasses from "../dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "../../../App.css";
import { css } from "@emotion/react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { GrFormView } from "react-icons/gr";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { FaDownload } from "react-icons/fa";

function Products() {
    const Navigate = useNavigate();
    let [loading, setLoading] = useState(true);
    const [Navactive, setNavactive] = useState(
        localStorage.getItem("active") || "1"
    );
    const [deliveryboys, setDeliveryboys] = useState([]);

    const [orders, setOrders] = useState([]);
    const [ordersSearch, setOrdersSearch] = useState([]);
    const token = { Authorization: `token ${sessionStorage.getItem("token")}` };
    const headerstyles = {
        color: "#707275",
        fontWeight: 700,
        fontSize: "0.8rem",
    };
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;
    const getcategories = () => {
        Services.getDeliveryBoy("GET", null, token)
            .then((res) => {
                setLoading(false);
                console.log(res);
                if (res.Status === 1) {
                    setDeliveryboys(res.Deliveryboys);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getorders = (status) => {
        let api = Services.getAllCurrentOrders("GET", null, token);
        if (status === "2") {
            api = Services.getAllPastOrders("GET", null, token);
        }
        api.then((res) => {
            console.log(res);
            setLoading(false);
            // setTabloader(false);
            if (res.Status === 1) {
                setOrders(res.Orders);
                // console.log(typeof res.Orders[0].orderStatus);
                setOrdersSearch(res.Orders);
            } else if (res.Status === 0) {
                toast.error(`${res.Message}`, {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                if (res.Message === "Token expired") {
                    Navigate("/");
                }
            }
        }).catch((err) => {
            // alert(err);
            alert("something went wrong please try again");
            console.log(err);
        });
    };
    useEffect(() => {
        getorders(Navactive);
        getcategories();
        // eslint-disable-next-line
    }, []);

    const Idformatter = (cell, cellcontent, index, formatExtraData) => {
        return (
            <div>
                <p className={dashboardclasses["idformatter"]}>{cell}</p>
            </div>
        );
    };
    const dateformatter = (cell, cellcontent) => {
        const date = new Date(cell);

        return <Moment format="MMM Do , hh:mm a">{date}</Moment>;
    };
    const viewOrderDetails = (e) => {
        const id = e.currentTarget.getAttribute("orderid");
        Navigate(`/orders/${id}`);
    };
    const ActionFormatter = (cell, cellcontent) => {
        return (
            <div className={dashboardclasses.view}>
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        window.open(
                            `https://clikgett.s3.amazonaws.com/orderinvoice/${cellcontent?.InvoiceFile}`
                        );
                    }}>
                    <FaDownload
                        size={16}
                        color="black"
                        id={cellcontent.OrderID}
                    />
                    {/* View */}
                </button>
                <button
                    onClick={viewOrderDetails}
                    orderid={cellcontent.OrderID}>
                    <GrFormView
                        size={22}
                        color="grey"
                        id={cellcontent.OrderID}
                    />
                    {/* View */}
                </button>
            </div>
        );
    };
    const getStatusDetails = (statusCode) => {
        switch (statusCode) {
            case 1:
                return { color: "green", statusName: "DELIVERED" };
            case 2:
                return { color: "orange", statusName: "PACKING" };
            case 3:
                return { color: "red", statusName: "Admin cancel order" };
            case 4:
                return { color: "red", statusName: "CANCELLED" };
            case 5:
                return {
                    color: "yellow",
                    statusName: "Picked Up",
                };

            default:
                return { color: "black", statusName: "Unknown status" };
        }
    };

    const priceFormatter = (cell) => {
        //className={dashboardclasses["priceformatter"]}
        return (
            <div className={dashboardclasses["price-wrapper"]}>
                <p>₹ {cell}</p>
            </div>
        );
    };
    const StatusFormatter = (cell) => {
        return (
            <p
                className={dashboardclasses["order_status"]}
                style={{
                    backgroundColor: getStatusDetails(cell).color,
                    color:
                        getStatusDetails(cell).statusName == "PROCESSING" ||
                        getStatusDetails(cell).statusName == "PACKING" ||
                        getStatusDetails(cell).statusName == "Picked Up"
                            ? "black"
                            : "white",
                }}>
                {" "}
                {getStatusDetails(cell).statusName}
            </p>
        );
    };
    const statusHandler = (e, cellContent) => {
        setLoading(true);
        let body = {
            OrderStatus: e.target.value,
            UserID: cellContent?.UserID,
        };
        Services.UpdateOrderDetails(
            "PUT",
            JSON.stringify(body),
            token,
            cellContent?.OrderID
        )
            .then((res) => {
                if (res.Status === 1) {
                    getorders();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const updateDeliveryBoy = (e, userId, OrderId) => {
        setLoading(true);
        let body = {
            UserID: userId,
            DeliveryBoyID: e.target.value,
        };
        Services.updateDeliveryboyOrder(
            "PUT",
            JSON.stringify(body),
            token,
            OrderId
        )
            .then((res) => {
                if (res.Status === 1) {
                    getorders();
                    toast.success(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    // setOrdersSearch(res.Orders);
                } else if (res.Status === 0) {
                    setLoading(false);
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const StatusHandler = (cell, cellContent) => {
        return (
            <div className={dashboardclasses["order_status"]}>
                <select
                    defaultValue={cell}
                    disabled={cell == 1 || cell == 4}
                    onChange={(e) => statusHandler(e, cellContent)}>
                    {" "}
                    <option value={1}>DELIVERED </option>
                    <option value={2}>PACKING</option>
                    <option value={4}>CANCELLED </option>
                    <option value={5}>PICKED UP</option>
                </select>
            </div>
        );
    };
    const UpdaetDelivery = (cell, cellContent) => {
        return (
            <div className={dashboardclasses["order_status"]}>
                <select
                    defaultValue={cell}
                    onChange={(e) =>
                        updateDeliveryBoy(
                            e,
                            cellContent?.UserID,
                            cellContent?.OrderID
                        )
                    }
                    disabled={
                        cellContent.OrderStatus == 1 ||
                        cellContent.OrderStatus == 4
                    }>
                    <option value="0" disabled>
                        Select Delivery Boy
                    </option>
                    {deliveryboys.map((item) => (
                        <option value={item?.AdminID}>
                            {item?.FirstName}{" "}
                            {item?.LastName != "NA" && item?.LastName}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    const columns = [
        {
            dataField: "OrderID",
            text: "ORDER ID",
            sort: false,
            // cell: (row, index) => index + 1,
            formatter: Idformatter,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                };
            },
        },
        /*    {
            dataField: "CartID",
            text: "Cart ID",
            sort: false,
            formatter: Idformatter,
            // cell: (row, index) => index + 1,
            headerStyle: (colum, colIndex) => {
                return {
                    ...headerstyles,
                };
            },
        }, */
        /*   {
            dataField: "MobileNumber",
            text: "MOBILE NUMBER",
            sort: false,

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        }, */
        {
            dataField: "CreatedAt",
            text: "ORDER DATE TIME",
            sort: false,
            formatter: dateformatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },

        {
            dataField: "GrandTotal",
            text: "AMOUNT",
            sort: false,
            formatter: priceFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles };
            },
        },
        {
            dataField: "OrderStatus",
            text: "ORDER STATUS",
            sort: false,
            formatter: StatusFormatter,
            tdStyle: () => {
                return {
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                };
            }, // Center-align text for this column

            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, textAlign: "center" };
            },
        },

        {
            dataField: "DeliveryBoyID",
            text: "DELIVERY BOY",
            sort: false,
            formatter: UpdaetDelivery,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
        {
            dataField: "OrderStatus",
            text: "STATUS",
            sort: false,
            formatter: StatusHandler,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
        {
            dataField: "ACTIONS",
            text: "ORDER DETAILS",
            sort: false,
            formatter: ActionFormatter,
            headerStyle: (colum, colIndex) => {
                return { ...headerstyles, "text-align": "center" };
            },
        },
    ];
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 8,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        hideSizePerPage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log("page", page);
            console.log("sizePerPage", sizePerPage);
        },
    });
    const defaultSorted = [
        {
            dataField: "name",
            order: "desc",
        },
    ];
    const searchHandler = (e) => {
        const search = orders.filter((item) =>
            String(item.OrderID).includes(e.target.value)
        );
        setOrdersSearch(search);
    };

    const navactivehandler = (e) => {
        setLoading(true);
        let status = e.currentTarget.getAttribute("id");
        setNavactive(status);
        localStorage.setItem("active", status);
        getorders(status);
    };
    return (
        <>
            <div className={dashboardclasses["dashboard-wrapper"]}>
                <div className={dashboardclasses["dashboard-leftside"]}>
                    <SideNav isactive="orders" />
                </div>
                <div className={dashboardclasses["dashboard-rightside"]}>
                    <TopNav />
                    <div className={dashboardclasses.CategoryWrapper}>
                        <h3>Orders</h3>
                        {loading ? (
                            <div className={dashboardclasses["loader"]}>
                                <ScaleLoader
                                    color={"black"}
                                    loading={true}
                                    css={override}
                                    size={150}
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        dashboardclasses["customer-form"]
                                    }>
                                    <input
                                        type={"text"}
                                        placeholder="Search by Orderid"
                                        onChange={searchHandler}
                                    />
                                </div>
                                <div
                                    className={
                                        dashboardclasses["orders-navbar"]
                                    }>
                                    <button
                                        id="1"
                                        className={
                                            Navactive === "1" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Current
                                    </button>
                                    <button
                                        id="2"
                                        className={
                                            Navactive === "2" &&
                                            dashboardclasses["order-active"]
                                        }
                                        onClick={navactivehandler}>
                                        Past
                                    </button>
                                </div>

                                <div className="category-table table_order">
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={ordersSearch}
                                        columns={columns}
                                        defaultSorted={defaultSorted}
                                        pagination={pagination}
                                        wrapperClasses="table-responsive"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Products;
