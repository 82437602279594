import { Routes, Route } from "react-router-dom";
import Login from "./Components/Login/login";
// import Signup from "./Components/Login/signup";
import ForgotPassword from "./Components/Login/forgotPassword";
import Warehouse from "./Components/dashboard/Warehouse/Warehouse";
import Category from "./Components/dashboard/Category/Category";
import Customers from "./Components/dashboard/Customers/Customers";
import Products from "./Components/dashboard/Products/Products";
import Orders from "./Components/dashboard/orders/Orders";
import Promocodes from "./Components/dashboard/promocode/Promocodes";
// import Settings from "./Components/dashboard/settings";
import Banners from "./Components/dashboard/banners/banners";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserDetails from "./Components/dashboard/Customers/userdetails";
// import Adminuser from "./Components/dashboard/adminusers/Adminusers";
import OrderDetails from "./Components/dashboard/orders/OrderDetails";
import FranchiseEnquiry from "./Components/dashboard/Franchise/Franchise";
import Callbackforms from "./Components/dashboard/callBackForm/callbackform";
import Availability from "./Components/dashboard/AvailabilityForm/availability";
import Notify from "./Components/dashboard/notifyProducts/notify";
import Subcategories from "./Components/dashboard/subcategories/subCategory";
import Details from "./Components/dashboard/Products/details";
import DeliverBoy from "./Components/dashboard/Deliveryboy/Delivery";
import RoutesComponent from "./Components/dashboard/Routes/Routes";
import Update from "./Components/dashboard/updateBulk/updateBulk";
import Notification from "./Components/dashboard/SendNotification/notification";
import Settings from "./Components/dashboard/Settings/settings";
import Kits from "./Components/dashboard/kits/Products";
import KitsDetails from "./Components/dashboard/kits/details";
import Operations from "./Components/dashboard/operations";
import Recommended from "./Components/dashboard/recommend/details";
import StoreTimings from './Components/dashboard/StoreTimings/Category'
function App() {
    return (
        <>
            <ToastContainer />
            <Routes>
                <Route exaact path="/" element={<Login />} />
                {/* <Route exaact path="/createaccount" element={<Signup />} /> */}
                <Route
                    exact
                    path="/forgotpassword"
                    element={<ForgotPassword />}
                />
                <Route exact path="/Warehouse" element={<Warehouse />} />
                <Route exact path="/categories" element={<Category />} />
                <Route exact path="/customers" element={<Customers />} />
                <Route exact path="/customers/:id" element={<UserDetails />} />
                <Route exact path="/banners" element={<Banners />} />
                <Route
                    exact
                    path="/products"
                    element={<Products />}
                />
                <Route
                    exact
                    path="/products/details/:productID"
                    element={<Details />}
                />
                <Route
                    exact
                    path="/kits/details/:productID"
                    element={<KitsDetails />}
                />
                <Route exact path="/orders" element={<Orders />} />
                <Route exact path="/store-timings" element={<StoreTimings />} />
                <Route exact path="/orders/:id" element={<OrderDetails />} />
                <Route exact path="/promocodes" element={<Promocodes />} />
                {/* <Route exaact path="/users" element={<Adminuser />} /> */}
                <Route
                    exact
                    path="/FranchiseEnquiry"
                    element={<FranchiseEnquiry />}
                />
                <Route
                    exact
                    path="/Callbackforms"
                    element={<Callbackforms />}
                />
                <Route
                    exact
                    path="/availabilityForms"
                    element={<Availability />}
                />
                <Route exact path="/notifyproducts" element={<Notify />} />
                {/* <Route exact path="/settings" element={<Settings />} /> */}
                <Route
                    exact
                    path="/subcategories"
                    element={<Subcategories />}
                />
                <Route exact path="/DeliveryBoy" element={<DeliverBoy />} />
                <Route exact path="/routes" element={<RoutesComponent />} />
                <Route exact path="/update-price" element={<Update />} />
                <Route exact path="/notification" element={<Notification />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/kits" element={<Kits />} />
                <Route exact path="/operations" element={<Operations />} />
                <Route exact path="/Recommended" element={<Recommended />} />
            </Routes>
        </>
    );
}

export default App;
