import { AiOutlineUnorderedList } from "react-icons/ai";
import { BiCloudDownload } from "react-icons/bi";
import { GiNewspaper } from "react-icons/gi";
import { HiOutlineLogout } from "react-icons/hi";
import {
  MdOutlineCategory,
  MdOutlineDeliveryDining,
  MdOutlineAltRoute,
  MdOutlineNotificationsActive,
} from "react-icons/md";
import { AiOutlineMenu, AiOutlineDown, AiOutlineGift } from "react-icons/ai";
import { CgShoppingBag, CgShoppingCart } from "react-icons/cg";
import { IoPricetagsOutline } from "react-icons/io5";
import { FiSettings } from "react-icons/fi";
import { MdOutlineKitchen } from "react-icons/md";
import { toast } from "react-toastify";
import Logo from "../../Assets/ricehub.png";
import { MdRecommend } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";


export const menuItems = [
  {
    id: 8,
    multiple: false,
    name: "Orders",
    menuLink: "/orders",
    active: "orders",
    icon: CgShoppingCart,
  },
  {
    id: 6,
    multiple: false,
    name: "Delivery Boys",
    menuLink: "/DeliveryBoy",
    active: "Deliveryboy",
    icon: MdOutlineDeliveryDining,
  },
  {
    id: 21,
    multiple: false,
    name: "Customers",
    menuLink: "/customers",
    active: "customers",
    icon: FaUserFriends,
  },
  {
    id: 1,
    multiple: false,
    name: "Categories",
    menuLink: "/categories",
    active: "categories",
    icon: AiOutlineUnorderedList,
  },
  {
    id: 3,
    multiple: false,
    name: "Sub categories",
    menuLink: "/subcategories",
    active: "subcategories",
    icon: MdOutlineCategory,
  },
  {
    id: 4,
    multiple: false,
    name: "Products",
    menuLink: "/products",
    active: "products",
    icon: MdOutlineCategory,
  },
  {
    id: 5,
    multiple: false,
    name: "Recommended Products",
    menuLink: "/Recommended",
    active: "Recommended",
    icon: MdOutlineCategory,
  },
  {
    id: 7,
    multiple: false,
    name: "Coupon Codes",
    menuLink: "/promocodes",
    active: "promocode",
    icon: AiOutlineGift,
  },
  {
    id: 2,
    multiple: false,
    name: "Banners",
    menuLink: "/banners",
    active: "banners",
    icon: GiNewspaper,
  },
  {
    id: 10,
    multiple: false,
    name: "Notification",
    menuLink: "/notification",
    active: "notification",
    icon: MdOutlineNotificationsActive,
  },
  {
    id: 10,
    multiple: false,
    name: "Store Details",
    menuLink: "/store-timings",
    active: "Store Timings",
    icon: IoStorefrontOutline,
  },



  /*  {
    id: 12,
    multiple: false,
    name: "Kits",
    menuLink: "/Kits",
    active: "kits",
    icon: CgShoppingBag,
   }, */




  // {
  //  id: 9,
  //  multiple: false,
  //  name: "Routes",
  //  menuLink: "/routes",
  //  active: "routes",
  //  icon: MdOutlineAltRoute,
  // },

  // {
  //  id: 11,
  //  multiple: false,
  //  name: "Settings",
  //  menuLink: "/settings",
  //  active: "settings",
  //  icon: FiSettings,
  // },
]